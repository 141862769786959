<template>
  <div class="line inline-block">
    <el-button size="small" @click="bulkDelTmp"> 批量删除 </el-button>
  </div>
</template>

<script>
import pullAll from 'lodash/pullAll'

export default {
  props: {
    selectedData: {
      type: Array,
      required: true
    },

    tableData: {
      type: Array,
      required: true
    }
  },

  methods: {
    async bulkDelTmp() {
      if (this.selectedData.length === 0) {
        this.$message.warning('请先选择需要批量删除的国家')
        return
      }
      try {
        await this.$confirm('此操作将永久批量删除选择的信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.$emit('resetData', pullAll(this.tableData, this.selectedData))
        this.$message.success('删除成功')
      } catch (err) {
        if (err === 'cancel') {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }
      }
    }
  }
}
</script>

<style></style>
