<template>
  <div class="app-container tmp-settings-page">
    <div class="top">
      <div class="title"><p>基础信息</p></div>
      <div class="information-warpper" v-loading="templateLoading">
        <TemplateForm :form="form" ref="templateForm" />
      </div>
    </div>
    <div style="height: 16px; background: #f0f2f5"></div>
    <div class="title"><p>规则信息</p></div>

    <div class="table-wrapper">
      <div class="table-wrapper-header">
        <div class="l-h">
          <BatchEdit :sup_this="sup_this" :tableData="data" @updateData="updateData" />
          <BulkDelTmp :sup_this="sup_this" :tableData="data" :selectedData="selectedData" @resetData="resetData" />
        </div>
        <div class="r-h">
          <AddCountryButton
            :disabledList="data"
            :selectedCountries.sync="willCheckedCountryData"
            :templateId="id"
            :sup_this="sup_this"
            @showCountryDialog="showCountryDialog"
            @hideAddCountryDialog="hideAddCountryDialog"
            @pushData="pushData"
          />
        </div>
      </div>

      <CommonTable
        ref="table"
        height="auto"
        :tableLoading="tableLoading"
        :cols="cols"
        :infoData="data"
        :spanMethod="spanMethod"
        @selection-change="selectHandler"
      >
        <template #createdListSlot="{ scoped: row }"> {{ getList(row) }} </template>
        <template #weightRangeSlot="{ scoped: row }"> {{ weightRange(row) }} </template>
        <template #firstFreightSlot="{ scoped: row }"> {{ row.firstWeight }} g / ￥{{ row.firstFreight }} </template>
        <template #continuedFreightSlot="{ scoped: row }">
          {{ row.continuedWeight }} g / ￥{{ row.continuedFreight }}
        </template>
        <slot>
          <el-table-column label="操作" width="300px" align="center">
            <template slot-scope="{ row }">
              <eEdit :sup_this="sup_this" :row="row" :tableData="data" @updateData="updateData" />
              <CopyBtn
                :row="row"
                :disabledList="data"
                :selectedCountries.sync="willCheckedCountryData"
                :templateId="id"
                :sup_this="sup_this"
                @showCountryDialog="showCountryDialog"
                @hideAddCountryDialog="hideAddCountryDialog"
                @pushData="pushData"
              />
              <DelTmp :sup_this="sup_this" :tableData="data" :data="row" @resetData="resetData" />
            </template>
          </el-table-column>
        </slot>
      </CommonTable>
    </div>
    <div class="save-btn-wrapper">
      <el-button class="save-btn" :loading="complexSaveLoading" @click="complexSave" type="primary">保存更新</el-button>
    </div>
    <paymentPassBox
      :loading.sync="loading"
      :passwordVal.sync="passwordVal"
      :payVisible.sync="payVisible"
      @commitPassword="commitPassword"
    ></paymentPassBox>
    <selectRegion
      ref="selectRegion"
      v-model="willCheckedCountryData"
      :type="isDomestic ? 'province' : 'country'"
      :visible.sync="visible"
      :disabledList="data"
    ></selectRegion>
  </div>
</template>

<script>
import { delMixin, initDataMixin } from '@/mixins'
import selectProvince from '../module/selectProvince'
import paymentPassBox from '@/views/components/payDialog'
import AddCountryButton from './module/addCountryButton'
import eEdit from './module/edit'
import BatchEdit from './module/batchEdit'
import CopyBtn from './module/copyBtn'
import DelTmp from './module/delTmp'
import BulkDelTmp from './module/bulkDelTmp'

import TemplateForm from './module/templateForm'
import CountryDialog from './module/countryDialog'
import selectRegion from '@/views/fedex/formwork/module/selectRegion/index.vue'

import { getTemplate, complexSave } from '@/api/template'
import { getCounrty } from '@/api/country'
import { firstWeightListCols, sameWeightListCols } from './cols'

import { FRIST_WEIGHT, SAME_WEIGHT } from '@/utils/constant'
import { cloneDeep, uniqBy } from 'lodash'
import { passwordSetted } from '@/api/balanceApi'
import { mapGetters } from 'vuex'
import { DOMESTIC_FORMWORK } from '@/utils/constant'

export default {
  components: {
    AddCountryButton,
    eEdit,
    BatchEdit,
    DelTmp,
    CopyBtn,
    BulkDelTmp,
    paymentPassBox,
    selectProvince,
    TemplateForm,
    CountryDialog,
    selectRegion
  },
  mixins: [initDataMixin, delMixin],

  data() {
    return {
      form: {},
      FRIST_WEIGHT,
      templateLoading: false,
      complexSaveLoading: false,
      dialogVisible: false,
      visible: false,
      willCheckedCountryData: [],
      forbiddenList: [],
      sup_this: this,
      size: 0,
      passwordVal: [],
      loading: false,
      payVisible: false,
      url: '/externaladmin/systemService/expressFreightTemplateConfig/list',
      mergeData: {
        isDeleted: 0,
        templateId: this.$route.query.id
      },
      countryList: [],
      id: this.$route.query.id && +this.$route.query.id
    }
  },

  created() {
    this.getTemplate()
    this.getCountryList()
  },

  computed: {
    ...mapGetters(['is_super']),
    isDomestic() {
      return this.$route.query.category == DOMESTIC_FORMWORK
    },
    //根据首重和一样重量两种不同的模式返回不同的数据
    cols() {
      const tmpObj = {
        [FRIST_WEIGHT]: firstWeightListCols,
        [SAME_WEIGHT]: sameWeightListCols
      }
      return tmpObj[this.form.calculateMode]
    },

    weightRange() {
      return (row) => {
        return `${row.startWeight}g ~ ${row.endWeight}g`
      }
    },

    //选择的国家
    selectedCountries({ data }) {
      const tmpArr = []
      data.map((item) => {
        const { countryId, countryName } = item
        const findItem = tmpArr.find((fItem) => {
          const { id } = fItem
          return countryId == id
        })
        if (!findItem) {
          tmpArr.push({
            id: countryId,
            countryName
          })
        }
      })
      return tmpArr
    }
  },
  watch: {
    data(n) {
      n.forEach((item) => {
        item.label = item.countryName
        item.value = item.countryId
      })
    }
  },

  methods: {
    getSelectedCountryData({ newestCheckedCountryData, historySelectedCountryList }) {
      this.willCheckedCountryData = [...newestCheckedCountryData, ...historySelectedCountryList]
    },
    onConfirmed(data) {
      this.willCheckedCountryData = data
    },
    showCountryDialog(checkedList) {
      this.willCheckedCountryData = uniqBy(checkedList, 'value')
      this.visible = true
    },

    hideAddCountryDialog() {
      this.willCheckedCountryData = []
    },

    pushData(fillData) {
      this.data.unshift(...fillData)
    },

    resetData(data) {
      this.data = []
      this.data.push(...data)
    },

    updateData(fillData) {
      const tmpObj = {}
      fillData.map((item) => {
        const { countryId } = item
        if (!tmpObj[countryId]) {
          tmpObj[countryId] = []
        }
        tmpObj[countryId].push(item)
      })

      for (let key in tmpObj) {
        let fIndex = -1
        const fData = this.data.filter(({ countryId }, index) => {
          const bool = countryId == key
          if (bool && fIndex == -1) {
            fIndex = index
          }
          return bool
        })
        const len = fData.length
        if (fIndex >= 0) {
          const list = tmpObj[key]
          this.data.splice(fIndex, len)
          for (let i = list.length; i--; i < 0) {
            this.data.splice(fIndex, 0, list[i])
          }
        }
      }
    },

    async getTemplate() {
      try {
        this.templateLoading = true
        const { code, detail } = await getTemplate(this.id)
        if ($SUC({ code })) {
          this.form = detail
        }
      } finally {
        this.templateLoading = false
      }
    },
    async commitPassword() {
      try {
        const { templateForm } = this.$refs
        if (!templateForm.formSave()) return
        this.complexSaveLoading = true
        const { calculateMode, id, isByActualWeight, isByVolumetricWeight, templateName, volumeDivisor } = this.form
        let params = {
          calculateMode,
          id,
          isByActualWeight,
          isByVolumetricWeight,
          templateName,
          volumeDivisor,
          list: cloneDeep(this.data).map((item, index) => {
            item.sortNumber = index
            item.templateId = this.id
            return item
          }),
          templateCategory: Number(this.$route.query.category)
        }
        if (!this.is_super) {
          const approveConfirmPassword = this.processPaymentPassword()
          if (!approveConfirmPassword) {
            return
          }
          params = Object.assign(params, { approveConfirmPassword })
        }
        this.loading = true

        const { code } = await complexSave(params)
        if ($SUC({ code })) {
          this.$message.success('保存成功，编辑后的模板状态将改为未启用，前往运费模板列表页。')
          this.payVisible = false
          setTimeout(() => {
            this.$router.replace('/fedex/formwork')
          }, 500)
        }
      } finally {
        this.complexSaveLoading = false
        this.loading = false
      }
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    },
    async complexSave() {
      try {
        if (!this.is_super) {
          const { code, detail } = await passwordSetted({})
          if (!$SUC(code)) {
            // 如果当前登录用户没有设置交易密码，则提示跳转并return
            if (detail == 0) {
              this.$message.warning('当前登录用户未设置交易密码')
            } else {
              this.passwordVal = []
              this.payVisible = true
            }
          }
        } else {
          this.commitPassword()
        }
      } catch (error) {}
    },

    selectHandler(selection) {
      this.selectedData = this.data.filter(({ countryId }) =>
        selection.map(({ countryId }) => countryId).includes(countryId)
      )
    },

    getList(data) {
      const list = this.countryList.find((item) => {
        return item.id === data.countryId
      })
      return list && list.countryCnName
    },

    //获取所有国家信息
    async getCountryList() {
      const { code, detail } = await getCounrty({
        isDeleted: 0,
        page: {
          pageIndex: 1,
          pageSize: 0
        }
      })

      if ($SUC({ code })) {
        this.countryList = (detail || []).map((item) => {
          item.countryName = item.countryCnName
          return item
        })
      }
    },

    spanMethod({ row, column, rowIndex, columnIndex }) {
      const { countryId } = row
      const { property } = column
      if (property === 'countryName' || !property) {
        let startIndex = -1
        const len = this.data.filter((item, index) => {
          const bool = item.countryId === countryId
          if (bool && startIndex == -1) {
            startIndex = index
          }
          return bool
        }).length
        if (rowIndex == startIndex) {
          return [len, 1]
        }
        return [0, 0]
      }
      return [1, 1]
    },
    initCallBack() {
      this.willCheckedCountryData = [...this.data]
    }
  }
}
</script>

<style lang="scss" scoped>
.tmp-settings-page {
  .save-btn-wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 80px;
    z-index: 1;
    left: 20px;
    right: 20px;
    background: #fff;
    justify-content: center;
    align-items: center;
    box-shadow: -1px -1px 5px rgb(224, 222, 222);
    .save-btn {
      // width: 100px;
      padding: 0 20px;
      text-align: center;
      height: 40px;
    }
  }
  .table-wrapper {
    padding: 20px;
    padding-bottom: 100px;
    .table-wrapper-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }
  .title {
    padding-left: 20px;
    line-height: 44px;
    border-bottom: 1px solid #e7e8f0;
    p {
      padding-top: 2px;
      padding-left: 7px;
      height: 16px;
      line-height: 16px;
      font-size: 16px;
      border-left: 3px solid #3841db;
      color: #595961;
    }
  }
}

.app-container {
  padding: 0;
  .top {
    height: 224px;
  }

  .information-warpper {
    padding: 20px 0 0 60px;
    font-size: 14px;
    color: #595961;
  }
}
</style>
